<template>
    <div class="d-flex align-items-center flex-wrap">
        <div class="col-12 text-center">
            <h1 class="text-warning">
                404
            </h1>
            <h2 class="font-monospace">
                {{ $t('notFound.lost') }}
            </h2>
        </div>
        <div class="col-12 text-center my-5">
            <img src="../assets/img/lost.png" class="w-25" alt="lost">
        </div>
        <div class="col-12 text-center">
            <router-link to="/" class="btn btn-lg btn-outline-dark font-monospace">
                <div class="d-flex align-items-center">
                    <i class="fa-solid fa-caret-left me-2"></i>
                    <span>
                        {{ $t('notFound.back') }}
                    </span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>

<style lang="scss" scoped>
    h1 {
        font-size: 100px;
    }
</style>