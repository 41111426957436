import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Auth from '../views/Auth.vue'
import Travels from '../views/Travels.vue'
import BankCards from '../views/BankCards.vue'
import BankCardsAdd from '../views/BankCardsAdd.vue'
import Payments from '../views/Payments.vue'
import Receipt from '../views/Receipt.vue'
import AccountsEdit from '../views/AccountsEdit.vue'
import ChangePassword from '../views/ChangePassword.vue'
import Privacy from '../views/Privacy.vue'
import AccountVerified from '../views/AccountVerified.vue'
import NotFound from '../views/NotFound.vue'
import store from '../store/store'

const routes = [
  { path: '/' },
  {
    path: '/log/in',
    name: 'Login',
    component: Auth,
    meta: {
      auth: false
    },
  },
  {
    path: '/sign/up',
    name: 'Sign',
    component: Auth,
    meta: {
      auth: false
    }
  },
  {
    path: '/account/:request',
    name: 'EmailService',
    component: Auth,
    meta: {
      auth: false
    }
  },
  {
    path: '/account/verified',
    name: 'AccountVerified',
    component: AccountVerified,
    meta: {
      auth: false
    }
  },
  {
    path: '/account/edit',
    name: 'AccountsEdit',
    component: AccountsEdit,
    meta: {
      auth: true
    }
  },
  {
    path: '/account/change_password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      auth: true
    }
  },
  {
    path: '/account/reset_password',
    name: 'ResetPassword',
    component: Auth,
    meta: {
      auth: false
    }
  },
  {
    path: '/travels',
    name: 'Travels',
    component: Travels,
    meta: {
      auth: true
    }
  },
  {
    path: '/bank_cards',
    name: 'BankCards',
    component: BankCards,
    meta: {
      auth: true
    },
    props: true
  },
  {
    path: '/bank_cards/add',
    name: 'BankCardsAdd',
    component: BankCardsAdd,
    meta: {
      auth: true
    }
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments,
    meta: {
      auth: true
    }
  },
  {
    path: '/payments/receipt',
    name: 'Receipt',
    component: Receipt,
    meta: {
      auth: true
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      auth: false
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if (to.path == '/') {
    router.replace('/log/in')
  }

  if ('auth' in to.meta && to.meta.auth && store.state.token == null) {
    return next({
      path: '/log/in'
    });
  } else if ('auth' in to.meta && !to.meta.auth && store.state.token) {
    return next({
      path: '/travels'
    });
  } else {
    return next();
  }

});


// router.afterEach((to) => {

//   if (to.name != 'Home' && to.name != 'Login' && to.name != 'Sign' && to.name != 'NotFound') {
//     document.title = to.name
//   } else if (to.name === 'NotFound') {
//     document.title = '404'
//   } else {
//     document.title = 'My Travels'
//   }
// });

export default router