<template>
    <div class="d-flex align-items-center flex-wrap">
        <h1 class="col-12">
            <i class="far fa-credit-card mx-2"></i>
            {{ $t('bankCards.adds.add') }}
        </h1>
    </div>
    <div class="d-flex justify-content-center flex-wrap my-4">
        <div class="col-12 d-flex justify-content-center">
            <img src="../assets/img/CreditCard.gif" alt="bank_card gif">
        </div>
        <div class="col-12 col-md-6 col-xxl-4">
            <p>{{ $t('bankCards.adds.travels') }}</p>
        </div>
        <div class="col-12 d-flex justify-content-center">
            <button  class="btn btn-success" @click="add">
                <i class="fa fa-credit-card"></i>
                {{ $t('bankCards.adds.button') }}
            </button>
        </div>
    </div>
    <transition name="fade-modal">
        <modal v-if="successModal" @close="successModal = false">
            <template v-slot:header>
                <div class="modal-header bg-success border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-cog me-2"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.successModal.header.googleSettings') }}
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="wrongModal" @close="wrongModal = false">
            <template v-slot:header>
                <div class="modal-header bg-danger border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="far fa-times-circle me-2 fs-4"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.wrongModal.header.edit') }}
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="addCardModal" @close="addCardModal = false">
            <template v-slot:header>
                <div class="modal-header bg-success border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="far fa-credit-card me-2"></i>
                        <h5 class="modal-title">
                            {{ $t('bankCards.adds.add') }}
                        </h5>
                    </div>
                </div>
            </template>
            <template v-slot:body>
                <div class="modal-body position-relative">
                    <IFrame @decoded_res="addCard" :class="!loader ? 'opacity-1' : 'opacity-0'"/>
                    <img src="../assets/img/load.gif" class="loader" :class="loader ? 'd-block' : 'd-none'">
                </div>
            </template>
            <template v-slot:footer>
                <div class="modal-footer d-flex justify-content-end">
                    <button type="button" class="btn btn-secondary" @click="addCardModal = false">
                        {{ $t('modal.deleteModal.footer.cancel') }}
                    </button>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="invalidCardModal" @close="invalidCardModal = false">
            <template v-slot:header>
                <div class="modal-header bg-danger border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="far fa-credit-card me-2"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.wrongModal.header.cardNotUsed') }}
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="existCardModal" @close="existCardModal = false">
            <template v-slot:header>
                <div class="modal-header bg-danger border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="far fa-credit-card me-2"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.wrongModal.header.cardAlreadyUsed') }}
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="addedModal" @close="addedModal = false">
            <template v-slot:header>
                <div class="modal-header bg-success border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="far fa-credit-card me-2"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.successModal.header.cardAdded') }}
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import { mapMutations, mapGetters } from "vuex"
import Modal from '../components/Modal.vue'
import IFrame from '../components/IFrame.vue'
export default {
    name: 'BankCardsAdd',
    components: {
        Modal,
        IFrame
    },
    data() {
        return {
            id: this.$store.state.account.id,
            successModal: false,
            wrongModal: false,
            addCardModal: false,
            addedModal: false,
            invalidCardModal: false,
            existCardModal: false,
            loader: true
        }
    },
    computed: {
        ...mapGetters(["apiPath", 'omsApiPath', 'omsApiKey']),
    },
    methods: {
        ...mapMutations(["setAccount"]),
        addCard(decoded_res) {
            if (decoded_res.tokens != null) {
                this.$http
                    .get(`${this.omsApiPath}/bank_cards/get/${this.omsApiKey}/1/0/${decoded_res.tokens[0]}`)
                    .then(
                        (res) => {
                            if (res.data.payload.length <= 0) {
                                this.addCardModal = false
                                this.invalidCardModal = true 
                                setTimeout(() => {
                                    this.invalidCardModal = false
                                    this.addCardModal = true
                                }, 1500);
                            } else {
                                var short_id = res.data.payload[0].short_id
                                this.$http
                                    .post(`${this.apiPath}/add_bank_card`, {id: this.$store.state.account.id, bank_cards: res.data.payload[0]}, {
                                        headers: {
                                            'x-api-key': this.$store.state.config.oms_api.key
                                        }
                                    })
                                    .then(
                                        (res) => {
                                            switch (res.data){
                                                case 'record inserted':
                                                    this.addCardModal = false
                                                    this.addedModal = true 
                                                    setTimeout(() => {
                                                        this.addedModal = false
                                                        this.$router.replace('/bank_cards')
                                                    }, 1500)
                                                    break
                                                case 'record already exist':
                                                    this.existCardModal = true 
                                                    setTimeout(() => {
                                                        this.existCardModal = false
                                                        this.$router.replace({name:'BankCards', params:{short_id}})
                                                    }, 1500);
                                                break
                                            }
                                        }
                                    )
                                    .catch((err) => {
                                        this.$store.state.serverModal = true
                                        this.$store.state.errServer = err.message
                                    })
                            }
                        }
                    )
                    .catch((err) => {
                        this.$store.state.serverModal = true
                        this.$store.state.errServer = err.message
                    })
            } else {
                this.addCardModal = false
                this.invalidCardModal = true 
                setTimeout(() => {
                    this.invalidCardModal = false
                    this.addCardModal = true
                }, 1500);
            }
            this.loader = true
            setTimeout(() => {
                this.loader = false
            }, 1500);
        },
        add() {
            this.addCardModal = true
            this.loader = true
            setTimeout(() => {
                this.loader = false
            }, 1500);
        }
    },
    created() {
        this.$i18n.locale = this.$store.state.account.languages_id
        this.$store.dispatch('sendNotification', this.$t('accounts.updateFields'))
    }
}
</script>

<style lang="scss" scoped>
    .opacity-0 {
        opacity: 0;
    }
    .opacity-1 {
        opacity: 1;
    }

    .loader {
        width: 20%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 768px) {
        img {
            width: 100%;
        }

        p {
            text-align: center;
        }
    }
</style>