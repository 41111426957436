<template>
    <pdf :src="`/${this.$store.state.config.oms_api.key}.pdf`" :page="1"></pdf>
    <pdf :src="`/${this.$store.state.config.oms_api.key}.pdf`" :page="2"></pdf>
    <pdf :src="`/${this.$store.state.config.oms_api.key}.pdf`" :page="3"></pdf>
</template>

<script>
import pdf from 'vue3-pdf'

export default {
    name: 'Privacy',
    components: {
        pdf
    }
}
</script>

<style lang="scss" scoped>

</style>