<template>
    <div :class="asideStatus ? 'backdrop' : ''" @click.self="setAside">
        <aside :class="[(asideStatus ? 'small' : ''), (this.$store.state.config.env == 'dev' ? 'dev' : ''), (this.$store.state.config.env == 'test' ? 'test' : '')]">
            <div class="logo-container d-flex justify-content-center">
                <img :src="require(`../assets/img/tenants/${this.$store.state.config.oms_api.key}.svg`)">
            </div>
            <div class="d-flex flex-wrap mt-4 px-2">
                <div class="col-12">
                    <router-link to="/travels" class="my-a aside-a" :class="asideStatus ? 'text-center' : ''">
                        <i class="fas fa-ticket-alt" :class="!asideStatus ? 'me-2' : ''"></i>
                        <span v-if="!asideStatus">
                            {{ $t("aside.myTravels") }}
                        </span>
                    </router-link>
                </div>
                <div class="col-12">
                    <router-link to="/bank_cards" class="my-a aside-a my-2" :class="[(asideStatus ? 'text-center' : ''), (this.$route.name === 'BankCardsAdd' ? 'router-link-active' : '')]">
                        <i class="fas fa-credit-card" :class="!asideStatus ? 'me-2' : ''"></i>
                        <span v-if="!asideStatus">
                            {{ $t("aside.myBankCards") }}
                        </span>
                    </router-link>
                </div>
                <div class="col-12">
                    <router-link to="/payments" class="my-a aside-a" :class="[(asideStatus ? 'text-center' : ''), (this.$route.name === 'Receipt' ? 'router-link-active' : '')]">
                        <i class="fas fa-money-bill-wave-alt" :class="!asideStatus ? 'me-2' : ''"></i>
                        <span v-if="!asideStatus">
                            {{ $t("aside.myPayments") }}
                        </span>
                    </router-link>
                </div>
            </div>
            <div class="d-flex flex-wrap aside-bottom">
                <div class="col-12 text-center px-4">
                    <img v-if="!asideStatus" src="../assets/img/ConduentLogo.svg" class="w-100" alt="Conduent">
                    <img v-else src="../assets/img/logo.png" class="w-100" alt="Conduent">
                </div>
                <div class="col-12 text-center mt-4">
                    <h5 class="text-white font-monospace">
                        v{{ this.$store.state.version.version }}
                    </h5>
                </div>
            </div>
        </aside>
    </div>
</template>

<script>
export default {
    name: 'Aside',
    computed: {
        asideStatus() {
            return this.$store.state.asideStatus
        }
    },
    methods: {
        setAside() {
            this.$store.commit('setAside')
        }
    }

}
</script>

<style lang="scss" scoped>

    aside{
        position: relative;
        max-width: 250px;
        height: inherit;
        min-height: 100%;
        background-color: #343a40;
        transition: 0.3s;

        .logo-container {
            padding: 20px 50px;
            border-bottom: 1px solid #fff;

            img {
                max-width: 100%;

                &.brescia-img {
                    border-radius: 5px;
                    box-shadow: 0 0 0 5px #fff;
                }
            }
        }

        .aside-bottom {
            position: absolute;
            left: 0;
            bottom: 10px;
        }

        .aside-a {
            display: block;
            padding: 12px;
            font-size: 18px;
            color: #fff;
            border-radius: 5px;

            &:hover {
                background-color: rgba(255,255,255,.1);
            }

            &.router-link-active {
                background-color: #007bff;
            }
        }

        &.small {
            display: block;
            transition: 0.3s;
            max-width: 4.6rem;

            .logo-container {
                padding: 5px;

                img {
                    box-shadow: 0 0 0 0 #fff;
                }
            }

        }
        &.dev {
            background-image: url('../assets/img/dev.svg');
        }
        &.test {
            background-image: url('../assets/img/test.svg');
        }
    }
    .fa-times {
        display: none;
    }
    
    @media screen and (max-width: 992px) {
        aside {
            display: none;
        }
        .fa-times {
            display: none;
        }
        .backdrop {
            position: fixed;
            inset: 0;
            background-color: rgba(black, 0.6);
            z-index: 99;  
            overflow-y: auto;  
            .fa-times {
                display: block;
                position: absolute;
                top: 15px;
                right: 15px;
                font-size: 25px;
                color: #3A9AB3;
            }
        }
    }
</style>