<template>
    <div v-if="this.$store.state.bank_cards.length > 0">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
            <h1 class="col-11 col-sm-6">
                <i class="fas fa-credit-card"></i>
                {{ $t('bankCards.myBankCards.myBankCards') }}
            </h1>
            <router-link to="/bank_cards/add" class=" col-1 col-sm-3 btn btn-success">
                <i class="fas fa-plus-circle me-2"></i>
                <span>
                    {{ $t('bankCards.myBankCards.addNewCard')}}
                </span>
            </router-link>
        </div>
        <div class="d-flex justify-content-center my-4">
            <div class="col-12 col-xxl-10 table-responsive">
                <Table :cards="this.$store.state.bank_cards" :matchCard="matchCard"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import Table from '../components/Table.vue'
export default {
    name: 'BankCards',
    components: {
        Table
    },
    data() {
        return {
            matchCard: ''
        }
    },
    computed: {
        ...mapGetters(["apiPath"]),
    },
    methods: { 
        getBankCards() {
            this.$http
                .post(`${this.apiPath}/get_bank_cards`, {accounts_id: this.$store.state.account.id}, {
                    headers: {
                        'x-api-key': this.$store.state.config.oms_api.key
                    }
                })
                .then(
                    (res) => {
                        if (res.data.length <= 0) {
                            this.$router.replace('/bank_cards/add')
                        } else {
                            this.$store.state.pans = []
                            res.data.forEach(
                                element => {
                                    element.label = this.$formatLabel(element.label)
                                    element.created = this.$formatDate(element.created, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    this.$store.state.pans += `/${element.alias_pan}`
                                }
                            );
                            this.$store.state.bank_cards = res.data
                        }
                    }
                )
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
        }
    },
    created() {
        this.$i18n.locale = this.$store.state.account.languages_id;
        this.getBankCards()
        
        if (Object.keys(this.$route.params).length != 0) {
            this.$store.state.bank_cards.forEach(
                element => {
                    if (element.alias_pan === this.$route.params.short_id) {
                        this.matchCard = this.$route.params.short_id
                    }
                }
            );
        }
    }
}
</script>

<style lang="scss" scoped>

    @media screen and (max-width: 992px) {
        span {
            display: none;
        }
        .btn-success {
            padding: 0;
            color: #198754;
            background-color: transparent;
            border-color: transparent;
        }
    }

</style>