<template>
    <footer class="p-3 d-flex flex-wrap bg-white">
        <small class="copy col-12 col-sm-9">
            Copyright © {{ year }} Conduent Business Solutions. {{ $t('footer.copy') }}
        </small>
    </footer>
</template>

<script>
import moment from 'moment';
export default {
    name: 'Footer',
    data() {
        return {
            year: ''
        }
    },
    methods: {
        getYear() {
            this.year = moment().year();
        }
    },
    created() {
        this.getYear()
    }
}
</script>