<template>
    <div v-if="this.$store.state.bank_cards.length > 0">
        <div class="d-flex align-items-center flex-wrap">
            <h1 class="col-12">
                <i class="fas fa-money-bill-wave-alt"></i>
                {{ $t('bankCards.myPayments.myPayments') }}
            </h1>
        </div>
        <div class="d-flex justify-content-center my-4">
            <div class="col-12 col-xxl-10 table-responsive">
                <Table :payments="this.$store.state.payments" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment";
import Table from "../components/Table.vue";
export default {
    name: "Payments",
    components: {
        Table
    },
    computed: {
        ...mapGetters(["apiPath", "omsApiPath", "omsApiKey"])
    }, 
    methods: {
        getBankCards() {
            return new Promise( (resolve) => {
                this.$http
                    .post(`${this.apiPath}/get_bank_cards`, { accounts_id: this.$store.state.account.id }, {
                        headers: {
                            "x-api-key": this.$store.state.config.oms_api.key,
                        }
                    })
                    .then((res) => {
                        if (res.data.length <= 0) {
                            this.$router.replace("/bank_cards/add");
                        } else {
                            this.$store.state.pans = [];
                            res.data.forEach((element) => {
                                element.label = this.$formatLabel(element.label)
                                element.created = this.$formatDate(element.created, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                this.$store.state.pans += `/${element.alias_pan}`
                            })
                            this.$store.state.bank_cards = res.data
                        }
                        resolve()
                    })
                    .catch((err) => {
                        this.$store.state.serverModal = true
                        this.$store.state.errServer = err.message
                    })
            })
        },
        getPayments() {
            var startDate = moment().subtract(29, "days").format("YYYY-MM-DD");
            var endDate = moment().format("YYYY-MM-DD");
            this.$http
                .get(`${this.omsApiPath}/payments/get/${this.omsApiKey}/10/0/4:desc/true/true/${startDate}/${endDate}${this.$store.state.pans}`)
                .then((res) => {
                    res.data.payload.forEach((element) => {
                        element.BankCard.label = this.$formatLabel(element.BankCard.label)
                        element.Payments.amount = this.$formatAmount(element.Payments.amount, element.Payments.currencies_code)
                        element.Payments.updated = this.$formatDate(element.Payments.updated , this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                    })
                    this.$store.state.payments = res.data.payload;
                })
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
        }
    },
    created() {
        this.$i18n.locale = this.$store.state.account.languages_id
        this.getBankCards().then(() => {
            this.getPayments()
        })
    }
}
</script>