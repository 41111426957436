<template>
    <main :class="[(this.$store.state.config.env == 'dev' ? 'dev' : ''), (this.$store.state.config.env == 'test' ? 'test' : '')]">
        <router-view/>
    </main>
</template>

<script>
export default {
    name: 'Main'
}
</script>

<style lang="scss" scoped>
    main {
        height: calc(100vh - 109px);
        padding: 10px;
        background-color: #f4f6f9;
        overflow: auto;

        &.dev {
            background-image: url('../assets/img/dev.svg');
        }
        &.test {
            background-image: url('../assets/img/test.svg');
        }
    }
</style>