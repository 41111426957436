import { createI18n } from 'vue-i18n/index';
import en from './translations/en.json'
import it from './translations/it.json'
import fr from './translations/fr.json'


const i18n = createI18n({
    locale: 'it',
    messages: {
      en,
      it,
      fr
    }
});


export default i18n;