<template>
    <div class="d-flex align-items-center flex-wrap">
        <h1 class="col-12">
            <i class="fas fa-user mx-2"></i>
            {{ $t('accounts.myAccount') }}
        </h1>
    </div>
    <div class="d-flex justify-content-center my-4">
        <div class="col-12 col-md-4">
            <form class="auth-form" autocomplete="off">
                <div class="d-flex flex-wrap">
                    <div class="col-12">
                        <label class="mb-2" for="current_password">
                            {{ $t('accounts.label.currentPassword') }}
                        </label>
                        <input v-model="currentPassword" @keyup="validateCurrentPassword" name="password" type="password" class="form-control" :placeholder="$t('accounts.label.currentPassword')" autocomplete="off" :class="errors.currentPassword ? 'is_invalid' : ''">
                        <small v-if="errors.currentPassword" class="is_invalid">
                            <i class="fas fa-exclamation-triangle me-2"></i>
                            {{ errors.currentPassword }}
                        </small>
                    </div>
                    <div class="col-12">
                        <label class="mt-3 mb-2" for="new_password">
                            {{ $t('accounts.label.newPassword') }}
                        </label>
                        <input v-model="newPassword" @keyup="validatePassword" name="newPassword"  type="password" class="form-control" :placeholder="$t('accounts.label.newPassword')" autocomplete="off">
                        <small v-if="errors.newPassword" class="is_invalid">
                            <i class="fas fa-exclamation-triangle me-2"></i>
                            {{ errors.newPassword }}
                        </small>
                    </div>
                    <div class="col-12">
                        <label class="mt-3 mb-2" for="repeat_newPassword">
                            {{ $t('accounts.label.repNewPassword') }}
                        </label>
                        <input v-model="repeat_newPassword" @keyup="validateRepPassword" name="repeat_newPassword" type="password" class="form-control" :placeholder="$t('auth.repPassword')" :class="errors.repeat_newPassword ? 'is_invalid' : ''" autocomplete="off">
                        <small v-if="errors.repeat_newPassword" class="is_invalid">
                            <i class="fas fa-exclamation-triangle me-2"></i>
                            {{ errors.repeat_newPassword }}
                        </small>
                    </div>
                    <div class="col-12">
                        <div class="d-flex justify-content-center submit mt-4">
                            <button type="button" @click="changePassword" class="btn btn-success w-100">
                                <i class="fas fa-save me-2"></i>
                                {{ $t('accounts.button.save') }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <transition name="fade-modal">
        <modal v-if="successModal" @close="successModal = false">
            <template v-slot:header>
                <div class="modal-header bg-success border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-key me-2"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.successModal.header.passwordEdited') }}
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import Modal from '../components/Modal.vue';
export default {
  components: { Modal },
    name: 'ChangePassword',
    data() {
        return {
            id: this.$store.state.account.id,
            currentPassword: '',
            newPassword: '',
            repeat_newPassword: '',
            errors: {
                currentPassword: '',
                newPassword: '',
                repeat_newPassword: ''
            },
            successModal: false,
            api_url: this.$store.state.config.api_url,
            api_port: this.$store.state.config.api_port
        }
    },
    computed: {
        ...mapGetters(["apiPath"]),
    }, 
    methods: {
        validateCurrentPassword() {

            if (this.currentPassword.length == 0 ) {
                return this.errors.currentPassword = this.$t('errors.required')
            } else {
                delete this.errors.currentPassword
            }
            this.validatePassword()
        },
        validatePassword() {
            const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

            if (this.newPassword.length == 0 ) {
                return this.errors.newPassword = this.$t('errors.required')
            } else if (this.newPassword.length < 8) {
                return this.errors.newPassword = this.$t('errors.password.length')
            } else if (!/\d/.test(this.newPassword)) {
                return this.errors.newPassword = this.$t('errors.password.number')
            } else if (!/[A-Z]/.test(this.newPassword)) {
                return this.errors.newPassword = this.$t('errors.password.uppercase')
            } else if (!/[a-z]/.test(this.newPassword)) {
                return this.errors.newPassword = this.$t('errors.password.lowercase')
            } else if (!format.test(this.newPassword)) {
                return this.errors.newPassword = this.$t('errors.password.special')
            } else if (this.newPassword == this.currentPassword) {
                return this.errors.newPassword = this.$t('errors.password.oldPassword')
            } else {
                delete this.errors.newPassword
            }
            this.validateRepPassword();

        },
        validateRepPassword() {

            if (this.repeat_newPassword.length == 0 ) {
                return this.errors.repeat_newPassword = this.$t('errors.required')
            } else if (this.repeat_newPassword != this.newPassword) {
                return this.errors.repeat_newPassword = this.$t('errors.password.match')
            } else {
                delete this.errors.repeat_newPassword
            }

        },
        ...mapMutations(["setAccount"]),
        changePassword() {
            this.$http
                .post(`${this.apiPath}/account/change_password`, {id: this.id, currentPassword: this.currentPassword, newPassword: this.newPassword, repeat_newPassword: this.repeat_newPassword}, {
                    headers: {
                        'x-api-key': this.$store.state.config.oms_api.key
                    }
                })
                .then(
                    (res) => {
                        if (res.data.account){
                            const account = res.data.account;
                            this.setAccount(account);
                            localStorage.setItem('session_account', JSON.stringify(res.data.account))
                            this.successModal = true
                            setTimeout(() => {
                                this.successModal = false
                                this.$router.replace('/account/edit')
                            }, 1500);
                        } else{
                            this.errors.currentPassword = this.$t('errors.password.invalid')
                        }
                    }
                )
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
        }
    }
}
</script>

<style lang="scss" scoped>
    .auth-form {
        padding: 30px;
        background-color: white;

        input{
            border: 1px solid #ced4da;
        }

        .submit {
            padding: 10px 30px;
            background-color: rgba(0,0,0,.03);
        } 
    }
</style>