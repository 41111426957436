<template>
    <div class="d-flex" v-if="isLoggedIn">
        <Aside/>
        <div class="w-100">
            <Navbar/>
            <Main/>
            <Footer/>
        </div>
    </div>
    <router-view v-else/>
    <transition name="fade-modal">
        <modal v-if="this.$store.state.serverModal" @close="this.$store.state.serverModal = false">
            <template v-slot:header>
                <div class="modal-header bg-danger border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-globe me-2"></i>
                        <h5 class="modal-title">
                            {{ this.$store.state.errServer }}
                        </h5>
                    </div>
                    <div class="d-flex align-items-center" @click="this.$router.go(`${this.$route.path}`)">
                        <i class="fas fa-rotate fs-4 cursor-pointer"></i>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from "vuex"
import Aside from "./components/Aside.vue"
import Main from "./components/Main.vue"
import Modal from "./components/Modal.vue"
import Navbar from "./components/Navbar.vue"
import Footer from "./components/Footer.vue"
export default {
    name: "MyTravels",
    components: {
        Aside,
        Navbar,
        Main,
        Modal,
        Footer,
    },
    computed: {
        ...mapGetters(["isLoggedIn", "apiPath"]),
    },
    methods: {
        getLanguages() {
            this.$http
                .get(`${this.apiPath}/languages`, {
                    headers: {
                        "x-api-key": this.$store.state.config.oms_api.key
                    },
                })
                .then((res) => {
                    this.$store.state.languages = res.data
                })
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
        },
        setFavicons(favImg) {
            let headTitle = document.querySelector("head")
            let setFavicon = document.createElement("link")
            setFavicon.setAttribute("rel", "icon")
            setFavicon.setAttribute("href", favImg)
            headTitle.appendChild(setFavicon)
        },
    },
    created() {
        switch (this.$store.state.config.oms_api.key) {
            case "f3aa64fa-cdc6-46de-ae37-c8890b9e527d":
                document.title = "ILA - Instant Lane Access"
                this.setFavicons("/amt.ico")
                $("body").css("background-color", "#47aeb3");
                break
            case "c21236b0-7f11-11ea-962c-08002754c58b":
                document.title = "Brescia Mobilità S.p.A."
                this.setFavicons("/brescia.ico")
                break
            case "188762d1-41c4-4fcc-88db-b0508d2e67ba":
                document.title = "Trasporto Pubblico e Locale - Linee Lecco"
                this.setFavicons("/lecco.ico")
                $("body").css("background-color", "#2a4f9d");
                break
            case "95a87b34-0cbf-11ec-b3b5-0800270bb7ac":
                document.title = "ACTV | Muoversi a Venezia"
                this.setFavicons("/actv.ico")
            break
        }
        this.$store.dispatch("autoLogin")
        this.getLanguages()
        if (this.isLoggedIn) {
            this.$store.dispatch('sendNotification', this.$t('accounts.updateFields'))
        }
    }
}
</script>

<style lang="scss">
    @import "./style/general";
</style>