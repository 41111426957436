<template>
    <div></div>
</template>

<script>
export default {
    name: 'AccountVerified',
    created() {
        window.close()
    }
}
</script>