import { createStore } from "vuex";
import config from '../../config.json'
import version from '../../version.json'

const store = createStore({
    state () {
      return {
        version,
        config,
        account: null,
        token: null,
        bank_cards: [],
        tickets: [],
        payments: [],
        receipt: null,
        pans: [],
        languages: [],
        asideStatus: false,
        reCaptcha: false,
        EmailSendService: null,
        notifications: [],
        FB: {},
        scope: {},
        serverModal: false,
        errServer: ''
      }
    },
    mutations: {
        setAccount(state, account) {
            state.account = account
        },
        setToken(state, token) {
            state.token = token
        },
        setAside(state) {
            state.asideStatus = !state.asideStatus
        }
    },
    getters: {
        isLoggedIn(state) {
            return !!state.token
        },
        apiPath(state) {
            return `${state.config.api_url}:${state.config.api_port}`
        },
        omsApiPath(state) {
            return state.config.oms_api.url
        },
        omsApiKey(state) {
            return state.config.oms_api.key
        }
    },
    actions: {
        autoLogin (context) {
            let session_account = localStorage.getItem('session_account')
            let session_token = localStorage.getItem('session_token')
            if (session_account && session_token) {
                context.commit('setToken', JSON.parse(session_token))
                context.commit('setAccount', JSON.parse(session_account))
            }
        },
        logOut (context) {
            context.commit('setToken', {token: null})
            context.commit('setAccount', {account: null})
            localStorage.removeItem('session_token')
            localStorage.removeItem('session_account')
        },
        sendNotification({state}, message) {
            if (state.account.name == '' || state.account.surname == '' || state.account.email == '')  {
                state.notifications = []
                state.notifications.push(
                    {
                        message,
                        action: '/account/edit'
                    }
                )
            } else {
                state.notifications = []
            }
        }
    }
})

export default store