import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import Axios from 'axios'
import i18n from './lang/i18n'
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import vue3GoogleLogin from 'vue3-google-login'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import timezone from "moment-timezone"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'jquery/dist/jquery'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-bs5'
import config from '../config.json'

const app = createApp(App)
app.config.globalProperties.$http = Axios
app.config.globalProperties.$formatDate = (date, locales_id, time_zone) => {
    return timezone.tz(timezone.utc(date), time_zone).locale(locales_id).format('L LTS')
}
app.config.globalProperties.$formatAmount = (amount, currency) => {
    amount = amount/100
    amount.toFixed(2)
    var formatter = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency
    })
    return formatter.format(amount)
}
app.config.globalProperties.$formatLabel = (label) => {
    switch (label) {
        case 'AMERICAN EXPRESS':
            label = 'AMEX'
        break;
        case 'MAESTRO':
            label = 'Maestro'
        break;
        case 'MASTERCARD':
            label = 'Mastercard'
        break;
        case 'VPAY':
            label = 'V_Pay'
        break;
        case 'VISA Electron':
            label = 'Visa_Electron'
        break;
        case 'VISA ELECTRON':
            label = 'Visa_Electron'
        break;
        case 'VISA':
            label = 'VISA'
        break;
        case 'VISA Debit/Credit':
            label = 'VISA'
        break;
    }
    return label
}
app.use(router)
app.use(store)
app.use(i18n)
app.use(VCalendar)
app.use(vue3GoogleLogin,{
    clientId: config.social_login.google.clientId,
    scope: 'profile email',
    prompt: 'select_account'
})
app.use(VueReCaptcha, { siteKey: config.recaptcha.site_key })
app.mount('#app')
