<template>
    <nav class="navbar-light bg-white p-3 d-flex justify-content-between align-items-center" :class="[(this.$store.state.config.env == 'dev' ? 'dev' : ''), (this.$store.state.config.env == 'test' ? 'test' : '')]">
        <div class="nav-left">
            <i class="fas fa-bars text-secondary not-small" @click="setAside"></i>
            <i class="fas fa-bars text-secondary is-small" @click="setAside"></i>
        </div>  
        <div class="nav-right d-flex align-items-center">
            <span class="location badge bg-secondary">
                <i class="fas fa-globe"></i>
                {{ currentTimeZone }}
                <i class="fas fa-globe"></i>
            </span>
            <div class="d-flex align-items-center ms-4">
                <ul class="navbar-nav">
                    <li class="nav-item dropdown">
                        <span class="position-relative" :data-bs-toggle="this.$store.state.notifications.length > 0 ? 'dropdown' : ''">
                            <i class="fa-regular fa-bell"></i>
                            <span v-if="this.$store.state.notifications.length > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {{ this.$store.state.notifications.length }}
                            </span>
                        </span>
                        <ul class="dropdown-menu drop-down dropdown-menu-end">
                            <li v-for="(notification, index) in this.$store.state.notifications" :key="index">
                                <router-link class="dropdown-item" :to="notification.action">
                                    {{ notification.message }}
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center ms-4">
                <ul class="navbar-nav">
                    <li class="nav-item dropdown">
                        <i class="far fa-user" role="button" data-bs-toggle="dropdown" aria-expanded="false" id="navbarDropdown"></i>
                        <span role="button" data-bs-toggle="dropdown" aria-expanded="false" id="navbarDropdown" class="account-info ms-2 me-4 ">
                            {{ account.name }}
                            <span class="text-uppercase">
                                {{ account.surname }}
                            </span>
                        </span>
                        <ul class="dropdown-menu drop-down dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li>
                                <router-link class="dropdown-item" to="/account/edit">
                                    <i class="fas fa-user me-2"></i>
                                    {{ $t('navbar.dropdown.myAccount') }}
                                </router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" to="/account/change_password">
                                    <i class="fas fa-key me-2"></i>
                                    {{ $t('navbar.dropdown.changePassword') }}
                                </router-link>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/log/in" @click="logOut">
                                    <i class="fas fa-sign-out-alt me-2"></i>
                                    {{ $t('navbar.dropdown.logOut') }}
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import $ from 'jquery'
import { googleLogout } from "vue3-google-login"
import { mapState, mapGetters } from "vuex";
export default {
    name: 'Navbar',
    data () {
        return {
            currentTimeZone : '',
            isSmall: false
        }
    },
    computed: {
        ...mapGetters(["isLoggedIn"]),
        ...mapState(["account"])
    },
    methods: {
        logOut() {
            this.$store.dispatch('logOut')
            if (this.$store.state.account.google_id != null) {
                googleLogout()
            }
            $('.grecaptcha-badge').show()
        },
        setAside() {
            this.$store.commit('setAside')
        }
    },
    created() {
        this.currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    }

}
</script>

<style lang="scss" scoped>
    nav {

        .fa-bars,
        .location {
            cursor: pointer;
        }
        
        .drop-down {
            left: unset;
            right: 0px;
        }

        .is-small {
            display: none;
        }

        &.dev {
            background-image: url('../assets/img/dev.svg');
        }
        &.test {
            background-image: url('../assets/img/test.svg');
        }
    }
    @media screen and (max-width: 576px) {
        .account-info {
            display: none;
        }
    }
    @media screen and (max-width: 768px) {
        nav {
            .not-small {
                display: none;
            }
            .is-small {
                display: block;
            }
        }
    }
</style>