<template>
    <table class="table table-bordered table-striped dataTable responsive my-4" id="datatable"  v-if="this.$route.name === 'BankCards'">
        <thead>
            <tr>
                <th class="text-center text-nowrap">
                    <i class="far fa-credit-card me-2"></i>
                    <span>
                        {{ $t('bankCards.myBankCards.number') }}
                    </span>
                </th>
                <th class="text-center text-nowrap table-popper">
                    <i class="far fa-clock me-2"></i>
                    <span>
                        {{ $t('bankCards.myBankCards.add') }}
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="card in cards" :key="card.id" :class="matchCard == card.alias_pan ? 'matched' : ''">
                <td class="d-flex justify-content-center align-items-center">
                    <img :src="require(`../assets/img/card_schemes/${card.label}.svg`)" :alt="card.label">
                    <span class="badge bg-primary mx-4">
                        {{ card.truncated_pan }}
                    </span>
                    <Popper :content="`${$t('bankCards.myBankCards.add')}  ${card.created}`" arrow>
                        <i class="fas fa-info-circle card-info"></i>
                    </Popper>
                </td>
                <td class="text-center table-popper">
                    <span class="badge bg-light text-dark">
                        {{ card.created }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex align-items-center flex-wrap table-top" v-if="this.$route.name === 'Travels' || this.$route.name === 'Payments'">
        <div class="col-12 col-md-4 calendar cursor-pointer" data-bs-toggle="dropdown">
            <div class="custom_filter justify-content-center calendar-filter">
                <i class="fas fa-calendar"></i>
                <input v-model="selectedDates" class="cursor-pointer" readonly>
            </div>
        </div>
        <ul class="dropdown-menu cursor-pointer">
            <li class="dropdown-item calendar-li" @click="getLast30Days">
                <small>
                    {{ $t('table.calendar.last30Days') }}
                </small>
            </li>
            <li class="dropdown-item calendar-li" @click="getThisMonth">
                <small>
                    {{ $t('table.calendar.thisMonth') }}
                </small>
            </li>
            <li class="dropdown-item calendar-li" @click="getLastMonth">
                <small>
                    {{ $t('table.calendar.lastMonth') }}
                </small>
            </li>
            <li class="dropdown-item calendar-li" @click="getLast12Months">
                <small>
                    {{ $t('table.calendar.last12Months') }}
                </small>
            </li>
            <li class="dropdown-item calendar-li" @click="calendar1Modal = true, attrs[1].dates = '', errors = ''">
                <small>
                    {{ $t('table.calendar.custom') }}
                </small>
            </li>
        </ul>
        <div class="col-12 col-md-4">
            <div class="custom_filter cards-filter">
                <i class="fas fa-credit-card"></i>
                <select @change="filteredByAliasPans">
                    <option value="">
                        {{ $t('table.select') }}
                    </option>
                    <option v-for="card in this.$store.state.bank_cards" :key="card.id" :value="card.alias_pan">
                        {{ card.truncated_pan }}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <table class="table table-bordered table-striped dataTable responsive my-4" id="datatable"  v-if="this.$route.name === 'Travels'">
        <thead>
            <tr>
                <th class="text-center text-nowrap">
                    <i class="fas fa-ticket-alt me-2"></i>
                    <span>
                        {{ $t('myTravels.table.fare') }}
                    </span>
                </th>
                <th class="text-center text-nowrap table-popper-lg">
                    <i class="far fa-clock me-2"></i>
                    <span>
                        {{ $t('myTravels.table.validFrom') }}
                    </span>
                </th>
                <th class="text-center text-nowrap table-popper-lg">
                    <i class="far fa-clock me-2"></i>
                    <span>
                        {{ $t('myTravels.table.validTo') }}
                    </span>
                </th>
                <th class="text-center text-nowrap table-popper-lg">
                    <i class="fas fa-thumbtack me-2"></i>
                    <span>
                        {{ $t('myTravels.table.firstValidation') }}
                    </span>
                </th>
                <th class="text-center text-nowrap table-popper-lg">
                    <i class="far fa-credit-card me-2"></i>
                    <span>
                        {{ $t('bankCards.myBankCards.myBankCards') }}
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="ticket in tickets" :key="ticket.id">
                <td class="td">
                    <span class="badge bg-secondary">
                        {{ ticket.name }}
                    </span>
                    <i class="fas fa-info-circle card-info-lg ms-2" @click="infoTicket(ticket), popperModal = true"></i>
                </td>
                <td class="text-center table-popper-lg">
                    <span class="badge bg-light text-dark">
                        {{ ticket.valid_from }}
                    </span>
                </td>
                <td class="text-center table-popper-lg">
                    <span class="badge bg-light text-dark">
                        {{ ticket.valid_to }}
                    </span>
                </td>
                <td class="text-center table-popper-lg">
                    <span class="badge bg-info"></span>
                </td>
                <td class="text-center table-popper-lg">
                    <img :src="require(`../assets/img/card_schemes/${ticket.label}.svg`)" :alt="ticket.label">
                    <span class="ms-2 badge bg-primary">
                        {{ ticket.truncated_pan }}
                    </span>
                </td>
            </tr>
        </tbody>
        <tbody v-if="this.$store.state.tickets.length == 0">
            <tr class="odd text-center">
                <td colspan="5">
                    {{ $t('table.zeroRecords') }}
                </td>
            </tr>
        </tbody>
    </table>
    <div v-if="this.$route.name === 'Payments'" class="d-flex filter-label align-items-center my-2">
        <input @click="filterByCompleted" v-if="this.$route.name === 'Payments'" type="checkbox" :checked="completed" id="completed">
        <label v-if="this.$route.name === 'Payments'" for="completed" class="cursor-pointer mx-2 badge bg-success">
            {{ $t('table.completed') }}
        </label>
        <input @click="filterByFailed" v-if="this.$route.name === 'Payments'" type="checkbox" :checked="failed" id="failed">
        <label v-if="this.$route.name === 'Payments'" for="failed" class="cursor-pointer mx-2 badge bg-danger">
            {{ $t('table.failed') }}
        </label>
    </div>
    <table class="table table-bordered table-striped dataTable responsive my-4" id="datatable"  v-if="this.$route.name === 'Payments'">
        <thead>
            <tr>
                <th class="text-center text-nowrap">
                    <span>ID</span>
                </th>
                <th class="text-center text-nowrap table-popper-md">
                    <i class="far fa-credit-card fa-fw me-2"></i>
                    <span>
                        {{ $t('bankCards.myBankCards.number') }}
                    </span>
                </th>
                <th class="text-center text-nowrap">
                    <i class="fas fa-money-bill-wave-alt fa-fw me-2"></i>
                    <span>
                        {{ $t('bankCards.myPayments.table.amount') }}
                    </span>
                </th>
                <th class="text-center text-nowrap table-popper-md">
                    <i class="fas fa-info-circle fa-fw me-2"></i>
                    <span>
                        {{ $t('bankCards.myPayments.table.status') }}
                    </span>
                </th>
                <th class="text-center text-nowrap table-popper-md">
                    <i class="far fa-clock fa-fw me-2"></i>
                    <span>
                        {{ $t('bankCards.myPayments.table.updated') }}
                    </span>
                </th>
                <th class="text-center text-nowrap table-popper-md">
                    <i class="fa-solid fa-file-invoice fa-fw me-2"></i>
                    <span>
                        {{ $t('bankCards.myPayments.table.receipt') }}
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="payment in payments" :key="payment.Payments.short_id" :class="payment.LastCharge.charge_results_name == 'SUCCEEDED' ? '' : 'bg-danger'">
                <td class="td">
                    <span class="badge bg-secondary">
                        {{ payment.Payments.short_id }}
                    </span>
                    <i class="fas fa-info-circle card-info-md ms-2" @click="paymentDetails(payment), popperModal = true"></i>
                    <i class="fa-solid fa-file-pdf card-info-md text-dark ms-2" @click="getPaymentInfo(payment.Payments.short_id)"></i>
                </td>
                <td class="text-center table-popper-md">
                    <img :src="require(`../assets/img/card_schemes/${payment.BankCard.label}.svg`)" :alt="payment.Payments.label">
                    <span class="ms-2 badge bg-primary">
                        {{ payment.BankCard.truncated_pan }}
                    </span>
                </td>
                <td class="text-center">
                    <span class="badge back-info">
                        {{ payment.Payments.amount }}
                    </span>
                </td>
                <td class="text-center table-popper-md">
                    <span class="badge" :class="payment.LastCharge.charge_results_name == 'SUCCEEDED' ? 'bg-success' : 'bg-danger'">
                        {{ payment.LastCharge.charge_results_name == 'SUCCEEDED' ? $t('table.completed') : $t('table.failed') }}
                    </span>
                </td>
                <td class="text-center table-popper-md">
                    <span class="badge bg-light text-dark">
                        {{ payment.Payments.updated }}
                    </span>
                </td>
                <td class="text-center table-popper-md">
                    <span v-if="payment.LastCharge.charge_results_name == 'SUCCEEDED'" class="btn btn-outline-success btn-sm" @click="getPaymentInfo(payment.Payments.short_id)">
                        <i class="fa-solid fa-file-pdf"></i>
                    </span>
                </td>
            </tr>
        </tbody>
        <tbody v-if="this.$store.state.payments.length == 0">
            <tr class="odd text-center">
                <td colspan="6">
                    {{ $t('table.zeroRecords') }}
                </td>
            </tr>
        </tbody>
    </table>

    <transition name="fade-modal">
        <modal v-if="calendar1Modal" @close="calendar1Modal = false" >
            <template v-slot:header>
                <div class="modal-header bg-primary border-0 rounded-0 text-light">
                    <h4 class="modal-title">
                        {{ $t('modal.calendarModal.headerFrom') }}
                    </h4>
                </div>
            </template>
            <template v-slot:body>
                <div class="modal-body d-flex justify-content-center">
                    <v-calendar  :attributes="attrs" title-position="left" trim-weeks is-expanded :max-date="attrs[0].dates" @dayclick="setCalendar1"/>
                </div>
            </template>
            <template v-slot:footer v-if="errors != ''">
                <div class="modal-footer d-flex justify-content-center">
                    <small class="is_invalid">
                        <i class="fas fa-exclamation-triangle me-2"></i>
                        {{ errors }}
                    </small>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="calendar2Modal" @close="calendar2Modal = false" >
            <template v-slot:header>
                <div class="modal-header bg-primary border-0 rounded-0 text-light">
                    <h4 class="modal-title">
                        {{ fromDate }} {{ $t('modal.calendarModal.headerTo') }}
                    </h4>
                </div>
            </template>
            <template v-slot:body>
                <div class="modal-body d-flex justify-content-center">
                    <v-calendar  :attributes="attrs" title-position="left" trim-weeks is-expanded :max-date="attrs[0].dates" @dayclick="setCalendar2"/>
                </div>
            </template>
            <template v-slot:footer>
                <div class="modal-footer d-flex justify-content-between">
                    <button type="button" class="btn btn-outline-primary" @click="calendar2Modal = false, calendar1Modal = true, this.errors = ''">
                        <i class="fas fa-caret-left"></i>
                        {{ $t('modal.calendarModal.footerTo') }}
                    </button>
                    <small v-if="errors != ''" class="is_invalid">
                        <i class="fas fa-exclamation-triangle me-2"></i>
                        {{ errors }}
                    </small>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="popperModal" @close="popperModal = false" >
            <template v-slot:header>
                <div class="modal-header back-info border-0 rounded-0 text-light">
                    <h4 v-if="this.$route.name === 'Travels'" class="modal-title d-flex align-items-center">
                        <i class="fas fa-info-circle me-2"></i>
                        {{ activeTicket.name }}
                    </h4>
                    <h4 v-if="this.$route.name === 'Payments'" class="modal-title d-flex align-items-center">
                        <i class="fas fa-info-circle me-2"></i>
                        Info
                    </h4>
                </div>
            </template>
            <template v-slot:body v-if="this.$route.name === 'Travels'">
                <div class="modal-body">
                    <div class="mb-2 d-flex justify-content-between align-items-center">
                        <strong class="me-2">
                            {{ $t('myTravels.table.validFrom') }}:
                        </strong>
                        <span class="badge bg-light text-dark">
                            {{ activeTicket.valid_from }}
                        </span>
                    </div>
                    <div class="mb-2 d-flex justify-content-between align-items-center">
                        <strong class="me-2">
                            {{ $t('myTravels.table.validTo') }}:
                        </strong>
                        <span class="badge bg-light text-dark">
                            {{ activeTicket.valid_to }}
                        </span>
                    </div>
                    <div class="mb-2 d-flex justify-content-between align-items-center">
                        <strong class="me-2">
                            {{ $t('myTravels.table.firstValidation') }}:
                        </strong>
                        <span class="badge bg-info"></span>
                    </div>
                    <div class="mb-2 d-flex justify-content-between align-items-center">
                        <strong class="me-2">
                            {{ $t('bankCards.myBankCards.myBankCards') }}:
                        </strong>
                        <div class="d-flex align-items-center">
                            <img :src="require(`../assets/img/card_schemes/${activeTicket.label}.svg`)" :alt="activeTicket.label">
                            <span class="ms-2 badge bg-primary">
                                {{ activeTicket.truncated_pan }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:body v-else>
                <div class="modal-body">
                    <div class="mb-2 d-flex justify-content-between align-items-center flex-wrap">
                        <strong class="me-2">
                            ID:
                        </strong>
                        <span class="badge bg-secondary">
                            {{ activePayment.Payments.short_id }}
                        </span>
                    </div>
                    <div class="mb-2 d-flex justify-content-between align-items-center">
                        <strong class="me-2">
                            {{ $t('bankCards.myBankCards.myBankCards') }}:
                        </strong>
                        <div class="d-flex align-items-center">
                            <img :src="require(`../assets/img/card_schemes/${activePayment.BankCard.label}.svg`)" :alt="activePayment.BankCard.label">
                            <span class="ms-2 badge bg-primary">
                                {{ activePayment.BankCard.truncated_pan }}
                            </span>
                        </div>
                    </div>
                    <div class="mb-2 d-flex justify-content-between align-items-center">
                        <strong class="me-2">
                            {{ $t('bankCards.myPayments.table.status') }}:
                        </strong>
                        <span class="badge" :class="activePayment.LastCharge.charge_results_name == 'SUCCEEDED' ? 'bg-success' : 'bg-danger'">
                            {{ activePayment.LastCharge.charge_results_name == 'SUCCEEDED' ? $t('table.completed') : $t('table.failed') }}
                        </span>
                    </div>
                    <div class="mb-2 d-flex justify-content-between align-items-center">
                        <strong class="me-2">
                            {{ $t('bankCards.myPayments.table.updated') }}:
                        </strong>
                        <span class="badge bg-light text-dark">
                            {{ activePayment.Payments.updated }}
                        </span>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import { mapGetters } from "vuex"
import Modal from '../components/Modal.vue'
import Popper from "vue3-popper"
export default {
    name: 'Table',
    components: {
        Modal,
        Popper
    },
    data() {
        return {
            selectedDates: '',
            fromDate: '',
            toDate: '',
            startDate: '',
            endDate: '',
            calendar1Modal: false,
            calendar2Modal: false,
            popperModal: false,
            day: '',
            month: '',
            year: '',
            attrs: [
                {
                    key: 'today',
                    dot: true,
                    dates: moment().format() ,
                },
                {
                    highlight: {
                        color: 'blue',
                        fillMode: 'light',
                    },
                    dates: '',
                },
            ],
            errors: '',
            activeTicket: '',
            activePayment: '',
            completed: true,
            failed: true
        }
    },
    props: {
        cards: Array,
        tickets: Array,
        payments: Array,
        matchCard: String
    },
    computed: {
        ...mapGetters(['omsApiPath', 'omsApiKey']),
    },
    methods: {
        getLast30Days() {
            var startDate = moment().subtract(29, 'days').format('YYYY-MM-DD')
            var endDate = moment().format('YYYY-MM-DD')
            if (this.$route.name === 'Travels') {
                this.$http
                    .get(`${this.omsApiPath}/tickets/get/${this.omsApiKey}/10/0/3:desc/${startDate}/${endDate}${this.$store.state.pans}`)
                    .then(
                        (res) => {
                            res.data.payload.forEach(
                                element => {
                                    element.label = this.$formatLabel(element.label)
                                    element.valid_from = this.$formatDate(element.valid_from, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    element.valid_to = this.$formatDate(element.valid_to, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                }
                            )
                            this.$store.state.tickets = res.data.payload;
                        }
                    )
                    .catch((err) => {
                        this.$store.state.serverModal = true 
                        this.$store.state.errServer = err.message
                    })
            } else if (this.$route.name === 'Payments') {
                this.$http
                    .get(`${this.omsApiPath}/payments/get/${this.omsApiKey}/10/0/4:desc/true/true/${startDate}/${endDate}${this.$store.state.pans}`)
                    .then(
                        (res) => {
                            res.data.payload.forEach(
                                element => {
                                    element.BankCard.label = this.$formatLabel(element.BankCard.label)
                                    element.Payments.amount = this.$formatAmount(element.Payments.amount, element.Payments.currencies_code)
                                    element.Payments.updated = this.$formatDate(element.Payments.updated , this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                }
                            )
                            this.$store.state.payments = res.data.payload;

                        }
                    )
                    .catch((err) => {
                        this.$store.state.serverModal = true       
                        this.$store.state.errServer = err.message
                    })
            }
            this.selectedDates = `${moment().subtract(29, 'days').format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`
            this.setActive()
        },
        getThisMonth() {
            var startDate = moment().startOf('month').format('YYYY-MM-DD')
            var endDate = moment().endOf('month').format('YYYY-MM-DD')
            if (this.$route.name === 'Travels') {
                this.$http
                    .get(`${this.omsApiPath}/tickets/get/${this.omsApiKey}/10/0/3:desc/${startDate}/${endDate}${this.$store.state.pans}`)
                    .then(
                        (res) => {
                            res.data.payload.forEach(
                                element => {
                                    element.label = this.$formatLabel(element.label)
                                    element.valid_from = this.$formatDate(element.valid_from, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    element.valid_to = this.$formatDate(element.valid_to, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                }
                            )
                            this.$store.state.tickets = res.data.payload;
                        }
                    )
                    .catch((err) => {
                        this.$store.state.serverModal = true     
                        this.$store.state.errServer = err.message
                    })
            } else if (this.$route.name === 'Payments') {
                this.$http
                    .get(`${this.omsApiPath}/payments/get/${this.omsApiKey}/10/0/4:desc/true/true/${startDate}/${endDate}${this.$store.state.pans}`)
                    .then(
                        (res) => {
                            res.data.payload.forEach(
                                element => {
                                    element.BankCard.label = this.$formatLabel(element.BankCard.label)
                                    element.Payments.amount = this.$formatAmount(element.Payments.amount, element.Payments.currencies_code)
                                    element.Payments.updated = this.$formatDate(element.Payments.updated , this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                }
                            )
                            this.$store.state.payments = res.data.payload;

                        }
                    )
                    .catch((err) => {
                        this.$store.state.serverModal = true   
                        this.$store.state.errServer = err.message
                    })
            }
            this.selectedDates = `${moment().startOf('month').format('DD/MM/YYYY')} - ${moment().endOf('month').format('DD/MM/YYYY')}`
            this.setActive()
        },
        getLastMonth() {
            var startDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
            var endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
            if (this.$route.name === 'Travels') {
                this.$http
                    .get(`${this.omsApiPath}/tickets/get/${this.omsApiKey}/10/0/3:desc/${startDate}/${endDate}${this.$store.state.pans}`)
                    .then(
                        (res) => {
                            res.data.payload.forEach(
                                element => {
                                    element.label = this.$formatLabel(element.label)
                                    element.valid_from = this.$formatDate(element.valid_from, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    element.valid_to = this.$formatDate(element.valid_to, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                }
                            )
                            this.$store.state.tickets = res.data.payload;
                        }
                    )
                    .catch((err) => {
                        this.$store.state.serverModal = true
                        this.$store.state.errServer = err.message
                    })
            } else if (this.$route.name === 'Payments') {
                this.$http
                    .get(`${this.omsApiPath}/payments/get/${this.omsApiKey}/10/0/4:desc/true/true/${startDate}/${endDate}${this.$store.state.pans}`)
                    .then(
                        (res) => {
                            res.data.payload.forEach(
                                element => {
                                    element.BankCard.label = this.$formatLabel(element.BankCard.label)
                                    element.Payments.amount = this.$formatAmount(element.Payments.amount, element.Payments.currencies_code)
                                    element.Payments.updated = this.$formatDate(element.Payments.updated , this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                }
                            )
                            this.$store.state.payments = res.data.payload;

                        }
                    )
                    .catch((err) => {
                        this.$store.state.serverModal = true
                        this.$store.state.errServer = err.message
                    })
            }
            this.selectedDates = `${moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY')} - ${moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY')}`
            this.setActive()
        },
        getLast12Months() {
            var startDate = moment().subtract(12, 'months').format('YYYY-MM-DD')
            var endDate = moment().format('YYYY-MM-DD')
            if (this.$route.name === 'Travels') {
                this.$http
                    .get(`${this.omsApiPath}/tickets/get/${this.omsApiKey}/10/0/3:desc/${startDate}/${endDate}${this.$store.state.pans}`)
                    .then(
                        (res) => {
                            res.data.payload.forEach(
                                element => {
                                    element.label = this.$formatLabel(element.label)
                                    element.valid_from = this.$formatDate(element.valid_from, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    element.valid_to = this.$formatDate(element.valid_to, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                }
                            )
                            this.$store.state.tickets = res.data.payload;
                        }
                    )
                    .catch((err) => {
                        this.$store.state.serverModal = true     
                        this.$store.state.errServer = err.message
                    })
            } else if (this.$route.name === 'Payments') {
                this.$http
                    .get(`${this.omsApiPath}/payments/get/${this.omsApiKey}/10/0/4:desc/true/true/${startDate}/${endDate}${this.$store.state.pans}`)
                    .then(
                        (res) => {
                            res.data.payload.forEach(
                                element => {
                                    element.BankCard.label = this.$formatLabel(element.BankCard.label)
                                    element.Payments.amount = this.$formatAmount(element.Payments.amount, element.Payments.currencies_code)
                                    element.Payments.updated = this.$formatDate(element.Payments.updated , this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                }
                            )
                            this.$store.state.payments = res.data.payload;

                        }
                    )
                    .catch((err) => {
                        this.$store.state.serverModal = true 
                        this.$store.state.errServer = err.message
                    })
            }
            this.selectedDates = `${moment().subtract(12, 'months').format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`
            this.setActive()
        },
        dataTable(){
            var lengthMenu = this.$t('table.lengthMenu')
            var info = this.$t('table.info')
            var infoEmpty = this.$t('table.infoEmpty')
            var infoFiltered = this.$t('table.infoFiltered')
            var search = this.$t('table.search')
            var zeroRecords = this.$t('table.zeroRecords')
            var next = this.$t('table.next')
            var previous = this.$t('table.previous')
            

            if (this.$route.name === 'BankCards') {
                $(document).ready( () => {
                    $('#datatable').DataTable({
                        responsive: true,
                        searching: false,
                        order: [ 0, 'desc' ],
                        language: {
                            lengthMenu,
                            search,
                            zeroRecords,
                            info,
                            infoEmpty,
                            infoFiltered,
                            paginate: {
                                next,
                                previous
                            }
                        }
                    });
                });
            } else if (this.$route.name === 'Travels' || this.$route.name === 'Payments') {
                $(document).ready( () => {
                    $('#datatable').DataTable({
                        responsive: true,
                        searching: false,
                        order: [ 0, 'desc' ],
                        language: {
                            lengthMenu,
                            search,
                            zeroRecords,
                            info,
                            infoEmpty,
                            infoFiltered,
                            paginate: {
                                next,
                                previous
                            }
                        }
                    });
                    $('div.dataTables_length').insertBefore('.calendar')
                    $('div.dataTables_length').addClass('col-12 col-md-4')
                });
            }
        },
        setActive() { 
            $(".calendar-li").click(() => {
                $(".active").removeClass("active")
                $(this).addClass("active")
            });
        },
        setCalendar1(day) {

            if($(day.el).hasClass('is-disabled')) {
                this.errors = this.$t('errors.calendar')
            } else {
                this.day = day.day
                this.month = day.month
                this.year = day.year
                this.errors = ''
                this.calendar1Modal = false
                this.calendar2Modal = true
                this.attrs[1].dates = day.id
                this.fromDate = day.id.split("-").reverse().join("/");
                this.startDate = day.id
            }
        },
        setCalendar2(day) {
            if($(day.el).hasClass('is-disabled') || day.day < this.day || day.month < this.month || day.year < this.year) {
                this.errors = this.$t('errors.calendar')
            } else {
                this.errors = ''
                this.attrs[1].dates = day.id
                this.calendar2Modal = false
                this.toDate = day.id.split("-").reverse().join("/");
                this.endDate = day.id

                if (this.$route.name === 'Travels') {
                    this.$http
                        .get(`${this.omsApiPath}/tickets/get/${this.omsApiKey}/10/0/3:desc/${this.startDate}/${this.endDate}${this.$store.state.pans}`)
                        .then(
                            (res) => {
                                res.data.payload.forEach(
                                    element => {
                                        element.label = this.$formatLabel(element.label)
                                        element.valid_from = this.$formatDate(element.valid_from, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                        element.valid_to = this.$formatDate(element.valid_to, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    }
                                )
                                this.$store.state.tickets = res.data.payload;
                            }
                        )
                        .catch((err) => {
                            this.$store.state.serverModal = true
                            this.$store.state.errServer = err.message
                        })
                    
                } else if (this.$route.name === 'Payments') {
                    this.$http
                        .get(`${this.omsApiPath}/payments/get/${this.omsApiKey}/10/0/4:desc/true/true/${this.startDate}/${this.endDate}${this.$store.state.pans}`)
                        .then(
                            (res) => {
                                res.data.payload.forEach(
                                    element => {
                                        element.BankCard.label = this.$formatLabel(element.BankCard.label)
                                        element.Payments.amount = this.$formatAmount(element.Payments.amount, element.Payments.currencies_code)
                                        element.Payments.updated = this.$formatDate(element.Payments.updated , this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    }
                                )
                                this.$store.state.payments = res.data.payload
                            }
                        )
                        .catch((err) => {
                            this.$store.state.serverModal = true
                            this.$store.state.errServer = err.message
                        })
                }

                this.selectedDates = `${this.fromDate} - ${this.toDate}`

            }
        },
        filteredByAliasPans(event) {
            if (this.$route.name === 'Travels') {
                if (event.target.value != '') {
                    this.$http
                        .get(`${this.omsApiPath}/tickets/get/${this.omsApiKey}/10/0/3:desc/1970-01-01/2999-01-01/${event.target.value}`)
                        .then(
                            (res) => {
                                res.data.payload.forEach(
                                    element => {
                                        element.label = this.$formatLabel(element.label)
                                        element.valid_from = this.$formatDate(element.valid_from, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                        element.valid_to = this.$formatDate(element.valid_to, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    }
                                )
                                this.$store.state.tickets = res.data.payload;
                            }
                        )
                        .catch((err) => {
                            this.$store.state.serverModal = true
                            this.$store.state.errServer = err.message
                        })
                } else {
                    this.$http
                        .get(`${this.omsApiPath}/tickets/get/${this.omsApiKey}/10/0/3:desc/1970-01-01/2999-01-01${this.$store.state.pans}`)
                        .then(
                            (res) => {
                                res.data.payload.forEach(
                                    element => {
                                        element.label = this.$formatLabel(element.label)
                                        element.valid_from = this.$formatDate(element.valid_from, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                        element.valid_to = this.$formatDate(element.valid_to, this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    }
                                )
                                this.$store.state.tickets = res.data.payload;
                            }
                        )
                        .catch((err) => {
                            this.$store.state.serverModal = true
                            this.$store.state.errServer = err.message
                        })
                }
            } else if (this.$route.name === 'Payments') {
                if (event.target.value != '') {
                    this.$http
                        .get(`${this.omsApiPath}/payments/get/${this.omsApiKey}/10/0/4:desc/true/true/1970-01-01/2999-01-01/${event.target.value}`)
                        .then(
                            (res) => {
                                res.data.payload.forEach(
                                    element => {
                                        element.BankCard.label = this.$formatLabel(element.BankCard.label)
                                        element.Payments.amount = this.$formatAmount(element.Payments.amount, element.Payments.currencies_code)
                                        element.Payments.updated = this.$formatDate(element.Payments.updated , this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    }
                                )
                                this.$store.state.payments = res.data.payload;

                            }
                        )
                        .catch((err) => {
                            this.$store.state.serverModal = true
                            this.$store.state.errServer = err.message
                        })
                } else {
                    this.$http
                        .get(`${this.omsApiPath}/payments/get/${this.omsApiKey}/10/0/4:desc/true/true/1970-01-01/2999-01-01${this.$store.state.pans}`)
                        .then(
                            (res) => {
                                res.data.payload.forEach(
                                    element => {
                                        element.BankCard.label = this.$formatLabel(element.BankCard.label)
                                        element.Payments.amount = this.$formatAmount(element.Payments.amount, element.Payments.currencies_code)
                                        element.Payments.updated = this.$formatDate(element.Payments.updated , this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                                    }
                                )
                                this.$store.state.payments = res.data.payload;

                            }
                        )
                        .catch((err) => {
                            this.$store.state.serverModal = true
                            this.$store.state.errServer = err.message
                        })
                }
            }
        },
        infoTicket(ticket) {
            this.activeTicket = ticket
        },
        paymentDetails(payment) {
            this.activePayment = payment
        },
        filterByCompleted() {
            this.completed = !this.completed
            var startDate = this.selectedDates.substring(0,10).split('/').reverse().join('/')
            var endDate = this.selectedDates.substring(13,23).split('/').reverse().join('/')
            startDate = startDate.replace('/','-')
            startDate = startDate.replace('/','-')
            endDate = endDate.replace('/','-')
            endDate = endDate.replace('/','-')

            this.$http
                .get(`${this.omsApiPath}/payments/get/${this.omsApiKey}/10/0/4:desc/${this.completed}/true/${startDate}/${endDate}${this.$store.state.pans}`)
                .then(
                    (res) => {
                        console.log(res);
                        res.data.payload.forEach(
                            element => {
                                element.BankCard.label = this.$formatLabel(element.BankCard.label)
                                element.Payments.amount = this.$formatAmount(element.Payments.amount, element.Payments.currencies_code)
                                element.Payments.updated = this.$formatDate(element.Payments.updated , this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                            }
                        )
                        this.$store.state.payments = res.data.payload;
                    }
                )
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
            this.failed = true
        },
        filterByFailed() {
            this.failed = !this.failed
            var startDate = this.selectedDates.substring(0,10).split('/').reverse().join('/')
            var endDate = this.selectedDates.substring(13,23).split('/').reverse().join('/')
            startDate = startDate.replace('/','-')
            startDate = startDate.replace('/','-')
            endDate = endDate.replace('/','-')
            endDate = endDate.replace('/','-')

            this.$http
                .get(`${this.omsApiPath}/payments/get/${this.omsApiKey}/10/0/4:desc/true/${this.failed}/${startDate}/${endDate}${this.$store.state.pans}`)
                .then(
                    (res) => {
                        res.data.payload.forEach(
                            element => {
                                element.BankCard.label = this.$formatLabel(element.BankCard.label)
                                element.Payments.amount = this.$formatAmount(element.Payments.amount, element.Payments.currencies_code)
                                element.Payments.updated = this.$formatDate(element.Payments.updated , this.$store.state.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
                            }
                        )
                        this.$store.state.payments = res.data.payload;
                    }
                )
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
            this.completed = true
        },
        getPaymentInfo(short_id) {
            this.$http
                .post(`${this.omsApiPath}/payments/get_info`, {short_id}, {
                    headers: {
                        "api-key": this.omsApiKey
                    }
                })
                .then((res) => {
                    res.data.bank_card.label = this.$formatLabel(res.data.bank_card.label)
                    res.data.payment.amount = this.$formatAmount(res.data.payment.amount, res.data.payment.currencies_code)
                    res.data.tickets.forEach(
                        ticket => {
                            ticket.valid_from = this.$formatDate(ticket.valid_from, this.$store.state.account.locales_id, ticket.time_zone)
                            ticket.valid_to = this.$formatDate(ticket.valid_to, this.$store.state.account.locales_id, ticket.time_zone)
                            ticket.ticket_amount = this.$formatAmount(ticket.ticket_amount, res.data.payment.currencies_code)
                            var transit_data = []
                            for (const [key, val] of Object.entries(ticket.first_validation_transit_data)) {
                                transit_data.push({key, val})
                            }
                            ticket.first_validation_transit_data = transit_data
                        }
                    )
                    this.$store.state.receipt = res.data
                    this.$router.replace('/payments/receipt')
                })
                .catch((err) => {
                    this.$store.state.serverModal = true
                    if (err.message.includes('400')) {
                        this.$store.state.errServer = this.$t('bankCards.myPayments.table.noReceipt')
                        setTimeout(() => {
                            this.$store.state.serverModal = false   
                        }, 1500);
                    } else { 
                        this.$store.state.errServer = err.message
                    }
                })
        }
    },
    created(){
        this.dataTable()
        this.selectedDates = `${moment().subtract(29, 'days').format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`
    },
  
}

</script>

<style lang="scss" scoped>

    .matched {
        animation: match 2s ease-out;
    }

    @keyframes match {
        0% {
            background-color: #3A9AB3;
        }
        100% {
            background-color: transparent;
        }
    }

    .dropdown-menu {
        inset: 0px auto auto 170px ;
    }

    .table-top {
        justify-content: space-between;
    }

    .filter-label {
        justify-content: flex-end;
    }

    .td {
        text-align: center;
    }

    .card-info,
    .card-info-md,
    .card-info-lg {
        display: none;
        color: #3A9AB3;
    }

    .back-info {
        background-color: #3A9AB3;
    }

    img {
        width: 35px;
    }

    @media screen and (max-width: 576px) {

        .card-info {
            display: block;
        }

        .table-popper {
            display: none;
        }

    }
    @media screen and (max-width: 768px) {

        .table-top,
        .filter-label {
            justify-content: center;
        }

        .card-info-md {
            display: block;
        }

        .table-popper-md {
            display: none;
        }
    }
    @media screen and (max-width: 992px) {

        .td {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .card-info-lg {
            display: block;
        }

        .table-popper-lg {
            display: none;
        }
    }
</style>