<template>
    <iframe :src="iFrameURL" width="100%" height="500px"></iframe>
</template>

<script>
const { v4: uuidv4 } = require('uuid') 
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
export default {
    name: 'IFrame',
    data() {
        return {
            iFrameURL: ''
        }
    },
    methods: {
        getIFrameUrl() {
            var transaction_id = uuidv4()
            
            var keyMAC = Base64.parse(this.$store.state.config.tokenizer.keyMac)

            var objectReq = {
                "shop_id": this.$store.state.config.tokenizer.shop_id,
                "transaction_id": transaction_id,
                "language": "it-IT"
            }
            var req = window.btoa(JSON.stringify(objectReq))
            var hash = hmacSHA256(req, keyMAC)
            var mac = Base64.stringify(hash)
            this.iFrameURL = `${this.$store.state.config.tokenizer.iFrameUrl}:${this.$store.state.config.tokenizer.iFramePort}/tokenize/req/${encodeURIComponent(req)}/mac/${encodeURIComponent(mac)}`

            window.addEventListener("message", (event) => {
                if (event.origin === `${this.$store.state.config.tokenizer.iFrameUrl}:${this.$store.state.config.tokenizer.iFramePort}`) {
                    var decoded_res = JSON.parse(window.atob(event.data.res))
                    this.$emit('decoded_res', decoded_res)
                }
            }, false)
        }
    },
    created() {
        this.getIFrameUrl()
    }
}
</script>