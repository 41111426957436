<template>
    <div class="d-flex align-items-center flex-wrap">
        <h1 class="col-12">
            <i class="fas fa-user mx-2"></i>
            {{ $t('accounts.myAccount') }}
        </h1>
    </div>
    <div v-if="this.$store.state.notifications.length > 0" class="d-flex justify-content-center align-items-center flex-wrap my-4">
        <h4 class="col-12 col-xxl-8 d-flex align-items-center bg-warning p-2">
            <i class="fa-solid fa-triangle-exclamation mx-2"></i>
            {{ $t('accounts.updateFields') }}
            (<i class="far fa-edit mx-2"></i>)
        </h4>
    </div>
    <div class="d-flex justify-content-center my-4">
        <div class="col-12 col-xxl-8">
            <form @submit.prevent="saveModal = true" @keyup.enter="checkMod" class="auth-form">
                <i :class="edit ? 'fas fa-times-circle' : 'far fa-edit'" @click="setEdit"></i>
                <div class="d-flex justify-content-between flex-wrap mb-2">
                    <div class="col-12 col-sm-5">
                        <label for="name">
                            {{ $t('accounts.label.name') }}
                        </label>
                        <transition name="fade" mode="out-in">
                            <p v-if="!edit">
                                {{ this.$store.state.account.name }}
                            </p>
                            <div v-else-if="edit">
                                <input v-model="name" @keyup="validateName" name="name" type="text" class="form-control" :placeholder="$t('auth.yourName')" autocomplete="off" :class="errors.name ? 'is_invalid' : '' "/>
                                <small v-if="errors.name && edit" class="is_invalid">
                                    <i class="fas fa-exclamation-triangle me-2"></i>
                                    {{ errors.name }}
                                </small>
                            </div>
                        </transition>
                    </div>
                    <div class="col-12 col-sm-5 surname">
                        <label for="surname">
                            {{ $t('accounts.label.surname') }}
                        </label>
                        <transition name="fade" mode="out-in">
                            <p v-if="!edit">
                                {{ this.$store.state.account.surname }}
                            </p>
                            <div v-else-if="edit">
                                <input v-model="surname" @keyup="validateSurname" name="surname" type="text" class="form-control" :placeholder="$t('auth.yourSurname')" autocomplete="off" :class="errors.surname ? 'is_invalid' : '' "/>
                                <small v-if="errors.surname && edit" class="is_invalid">
                                    <i class="fas fa-exclamation-triangle me-2"></i>
                                    {{ errors.surname }}
                                </small>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="col-12 col-sm-5">
                        <label for="email">
                            {{ $t('accounts.label.email') }}
                        </label>
                        <transition name="fade" mode="out-in">
                            <p v-if="!edit">
                                {{ this.$store.state.account.email }}
                            </p>
                            <div v-else-if="edit">
                                <input v-model="email" @keyup="validateEmail" name="email" type="email" class="form-control" :placeholder="$t('auth.yourEmail')" autocomplete="off" :class="errors.email ? 'is_invalid' : '' "/>
                                <small v-if="errors.email && edit" class="is_invalid">
                                    <i class="fas fa-exclamation-triangle me-2"></i>
                                    {{ errors.email }}
                                </small>
                            </div>
                        </transition>
                    </div>
                    <div class="col-12 col-sm-5 lang">
                        <label for="languages_id">
                            {{ $t("accounts.label.language") }}
                        </label>
                        <transition name="fade" mode="out-in">
                            <div v-if="!edit">
                                <p v-if="this.$store.state.account.languages_id === 'en'"> English </p>
                                <p v-else-if="this.$store.state.account.languages_id === 'fr'"> Français </p>
                                <p v-if="this.$store.state.account.languages_id === 'it'"> Italiano </p>
                            </div>
                            <div v-else-if="edit">
                                <select v-model="languages_id" name="languages_id" class="form-control" @change="setLanguages">
                                    <option v-for="language in this.$store.state.languages" :key="language.id" :value="language.id" :selected="language.id == languages_id">
                                        {{ language.name }}
                                    </option>
                                </select>
                            </div>
                        </transition>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="edit" class="my-4">
                        <label class="switch">
                            <input type="checkbox" v-model="notify_ticket_issuance" />
                            <span class="slider round"></span>
                        </label>
                        <span class="ms-2">
                            {{ $t('accounts.label.ticket') }}
                        </span>
                    </div>
                </transition>
                <transition name="fade">
                    <div class="d-flex justify-content-center align-items-center flex-wrap submit" v-if="edit">
                        <button type="button" @click="saveModal = true" @mouseup="checkMod" class="btn btn-success w-75" :disabled="Object.keys(errors).length != 0">
                            <i class="fas fa-save me-2"></i>
                            {{ $t('accounts.button.save') }}
                        </button>
                        <hr class="w-100"/>
                        <button type="button" @click="deleteModal = true" class="btn btn-danger w-75">
                            <i class="fas fa-trash me-2"></i>
                            {{ $t('accounts.button.delete') }}
                        </button>
                    </div>
                </transition>
            </form>
        </div>
    </div>
    <transition name="fade-modal">
        <modal v-if="saveModal && Object.keys(edited).length != 0" @close="closeModal">
            <template v-slot:header>
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('modal.saveModal.header') }}
                    </h5>
                </div>
            </template>
            <template v-slot:body>
                <div class="modal-body">
                    <div v-for="(editate, index) in edited" :key="index">
                        {{ $t(`accounts.label.${index}`) }} : {{ editate }}
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="modal-footer">
                    <form @submit.prevent="editAccount">
                        <button type="button" @click="closeModal" class="btn btn-secondary me-2">
                            {{ $t('modal.deleteModal.footer.cancel') }}
                        </button>
                        <button type="submit" class="btn btn-success">
                            {{ $t('accounts.button.save') }}
                        </button>
                    </form>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="successModal" @close="closeModal">
            <template v-slot:header>
                <div class="modal-header bg-success border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="far fa-check-circle me-2 fs-4"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.successModal.header.edit') }}
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="wrongModal" @close="closeModal">
            <template v-slot:header>
                <div class="modal-header bg-danger border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="far fa-times-circle me-2 fs-4"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.wrongModal.header.edit') }}
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="deleteModal" @close="closeModal">
            <template v-slot:header>
                <div class="modal-header bg-danger border-0 rounded-0 text-light">
                    <h5 class="modal-title">
                        {{ $t('modal.deleteModal.header') }}
                    </h5>
                </div>
            </template>
            <template v-slot:body>
                <div class="modal-body">
                    <div>
                        {{ $t('modal.deleteModal.body') }}
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="modal-footer">
                    <form @submit.prevent="deleteAccount">
                        <button type="button" @click="closeModal" class="btn btn-secondary me-2">
                            {{ $t('modal.deleteModal.footer.cancel') }}
                        </button>
                        <button type="submit" class="btn btn-danger">
                            <i class="fas fa-trash me-2"></i>
                            {{ $t('modal.deleteModal.footer.delete') }}
                        </button>
                    </form>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="delWrongModal" @close="closeModal">
            <template v-slot:header>
                <div class="modal-header bg-danger border-0 rounded-0 text-light">
                    <div class="d-flex align-items-center">
                        <i class="far fa-sad-tear me-2 fs-4"></i>
                        <h5 class="modal-title">
                            {{ $t('modal.wrongModal.header.delete') }}
                        </h5>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { googleLogout } from "vue3-google-login"
import Modal from "../components/Modal.vue";
export default {
    name: "AccountsEdit",
    components: {
        Modal
    },
    data() {
        return {
            edit: false,
            id: this.$store.state.account.id,
            name: this.$store.state.account.name,
            surname: this.$store.state.account.surname,
            email: this.$store.state.account.email,
            languages_id: this.$store.state.account.languages_id,
            notify_ticket_issuance: this.$store.state.account.notify_ticket_issuance,
            errors: {},
            edited: {},
            saveModal: false,
            successModal: false,
            wrongModal: false,
            delWrongModal: false,
            deleteModal: false
        }
    },
    computed: {
        ...mapGetters(["apiPath"]),
    },
    methods: {
        validateName() {
            if (this.name.trim().length == 0) {
                this.edited = {}
                return (this.errors.name = this.$t("errors.required"))
            } else {
                delete this.errors.name
            }
        },
        validateSurname() {
            if (this.surname.trim().length == 0) {
                this.edited = {}
                return (this.errors.surname = this.$t("errors.required"))
            } else {
                delete this.errors.surname
            }
        },
        validEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(email)
        },
        validateEmail() {
            if (this.email.length == 0) {
                this.edited = {}
                return (this.errors.email = this.$t("errors.required"))
            } else if (!this.validEmail(this.email)) {
                this.edited = {}
                return (this.errors.email = this.$t("errors.email.invalid"))
            } else {
                this.$http
                    .post(`${this.apiPath}/account_exists`, { email: this.email },
                        {
                            headers: {
                                "x-api-key": this.$store.state.config.oms_api.key,
                            }
                        }
                    )
                    .then((res) => {
                        if (res.data.email) {
                            this.errors.email = this.$t('errors.email.exist')
                        } else {
                            delete this.errors.email
                        }
                    })
                    .catch((err) => {
                        this.$store.state.serverModal = true
                        this.$store.state.errServer = err.message
                    })
            }
        },
        setLanguages(event) {
            this.$i18n.locale = event.target.value
            this.allValidation()
        },
        allValidation() {
            this.validateName()
            this.validateSurname()
            this.validateEmail()
        },
        setEdit() {
            this.$i18n.locale = this.$store.state.account.languages_id
            this.edit = !this.edit
            this.name = this.$store.state.account.name,
            this.surname = this.$store.state.account.surname,
            this.email = this.$store.state.account.email,
            this.languages_id = this.$store.state.account.languages_id,
            this.notify_ticket_issuance = this.$store.state.account.notify_ticket_issuance,
            this.errors = {}
        },
        ...mapMutations(["setAccount"]),
        editAccount() {
            this.$http
                .post(`${this.apiPath}/account/edit`,
                    {
                        id: this.id,
                        name: this.name,
                        surname: this.surname,
                        email: this.email,
                        languages_id: this.languages_id,
                        notify_ticket_issuance: this.notify_ticket_issuance
                    },
                    {
                        headers: {
                            "x-api-key": this.$store.state.config.oms_api.key,
                        }
                    }
                )
                .then((res) => {
                    if (res.data.account) {
                        const account = res.data.account
                        this.setAccount(account)
                        localStorage.setItem("session_account", JSON.stringify(res.data.account))
                        this.saveModal = false
                        this.successModal = true
                        setTimeout(() => {
                            this.successModal = false
                            this.edit = false
                            this.$store.state.notifications = []
                        }, 1500)
                    } else {
                        this.saveModal = false
                        this.wrongModal = true
                        setTimeout(() => {
                            this.wrongModal = false
                        }, 1500)
                    }
                })
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
        },
        checkMod() {
            if (Object.keys(this.errors).length != 0) {
                this.wrongModal = true
                setTimeout(() => {
                    this.wrongModal = false
                }, 1500)
            } else {
                if (this.name.trim() != this.$store.state.account.name) {
                    this.edited.name = this.name
                }
                if (this.surname.trim() != this.$store.state.account.surname) {
                    this.edited.surname = this.surname
                }
                if (this.email != this.$store.state.account.email) {
                    this.edited.email = this.email
                }
                if (this.languages_id != this.$store.state.account.languages_id) {
                    if (this.languages_id === "it") {
                        this.edited.language = "Italiano"
                    } else if (this.languages_id === "fr") {
                        this.edited.language = "Français"
                    } else {
                        this.edited.language = "English"
                    }
                }
                if (this.notify_ticket_issuance != this.$store.state.account.notify_ticket_issuance) {
                    if (this.notify_ticket_issuance) {
                        this.edited.ticket = this.$t("accounts.label.on")
                    } else {
                        this.edited.ticket = this.$t("accounts.label.off")
                    }
                }
                if (Object.keys(this.edited).length == 0) {
                    this.saveModal = false
                    this.wrongModal = true
                    setTimeout(() => {
                        this.wrongModal = false
                    }, 1500)
                }
            }
        },
        deleteAccount() {
            this.$http
                .post(`${this.apiPath}/account/delete`, { id: this.id }, 
                {
                    headers: {
                        "x-api-key": this.$store.state.config.oms_api.key,
                    }
                }
                )
                .then((res) => {
                    if (res.data) {
                        this.$store.dispatch("logOut")
                        if (this.$store.state.account.google_id != null) {
                            googleLogout()
                        }
                        this.deleteModal = false
                        this.delWrongModal = true
                        setTimeout(() => {
                            this.$router.go("/sign/up")
                        }, 1500)
                    }
                })
                .catch((err) => {
                    this.$store.state.serverModal = true
                    this.$store.state.errServer = err.message
                })
        },
        closeModal() {
            this.saveModal = false
            this.successModal = false
            this.wrongModal = false
            this.deleteModal = false
            this.edited = {}
        }
    }
}
</script>

<style lang="scss" scoped>
    .auth-form {
        position: relative;
        padding: 30px;
        background-color: white;

        i.fa-edit,
        i.fa-times-circle {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }

        input,
        select {
            border: 1px solid #ced4da;
        }

        p {
            margin: 0;
            padding: 0.375rem 0.75rem;
            border-bottom: 2px solid #3a9ab3;
        }

        .submit {
            padding: 10px;
            background-color: rgba(0, 0, 0, 0.03);
        }
    }
    @media screen and (max-width: 576px) {
        .surname,
        .lang {
            margin: 10px 0;
        }
    }
</style>