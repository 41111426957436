<template>
    <component :is="api_key"></component>
</template>

<script>
import { defineAsyncComponent } from "vue"
import { mapState } from 'vuex'
export default {
    name: 'Auth',
    computed: {
        ...mapState(["config"]),
        api_key() {
            return defineAsyncComponent(() => import(`../components/layouts/${this.config.oms_api.key}/views/Auth.vue`))
        }
    }
}
</script>