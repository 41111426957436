<template>
    <div class="d-flex justify-content-between align-items-center flex-wrap">
        <h1 class="col-6">
            <i class="fa-solid fa-file-invoice"></i>
            {{ $t('bankCards.myReceipt.receipt') }}
        </h1>
        <h1 class="col-6 text-end">
            <span class="btn btn-lg bg-success text-white" @click="downloadPdf">
                <i class="fa-solid fa-download"></i>
            </span>
        </h1>
    </div>
    <div class="d-flex justify-content-center my-4">
        <div class="col-12 col-xxl-8 bg-white" id="receipt_pdf">
            <header class="d-flex justify-content-between align-items-center flex-wrap p-4">
                <div class="col-6">
                    <img :src="require(`../assets/img/tenants/${this.$store.state.config.oms_api.key}.svg`)" class="w-75">
                </div>
                <div class="col-12 mt-2">
                    <span v-if="this.$store.state.config.oms_api.key == '188762d1-41c4-4fcc-88db-b0508d2e67ba'">
                        LINEE LECCO S.P.A. <br>
                        PIAZZA BIONE N.15 <br>
                        23900 LECCO (LC) <br>
                        P.IVA: 02320500131 <br>
                        C.F.:92004770134 <br>
                    </span>
                </div>
            </header>
            <main v-if="receipt != null" class="d-flex justify-content-between align-items-center flex-wrap p-4">
                <div class="col-12 d-flex align-items-center flex-wrap">
                    <div class="col-12 col-sm-6 payment-info d-flex justify-content-start align-items-center">
                        <img :src="require(`../assets/img/card_schemes/${receipt.bank_card.label}.svg`)" class="w-25">
                        <span class="ms-2 badge bg-primary fs-6">{{ receipt.bank_card.truncated_pan }}</span>
                    </div>
                    <div class="col-12 col-sm-6 payment-info d-flex justify-content-end align-items-center">
                        <span class="fs-4 fw-bold text-nowrap">
                            {{ this.$store.state.account.name }} 
                            <span class="text-uppercase">
                                {{ this.$store.state.account.surname }}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-between align-items-center flex-wrap mt-3 mb-4">
                    <div class="col-12 col-sm-6 payment-info d-flex justify-content-start align-items-center">
                        <span class="receipt-title fs-6 ms-2 p-2">
                            <span class="font-monospace">
                                {{ receipt.payment.short_id }}
                            </span>
                        </span>
                    </div>
                    <div class="col-12 col-sm-6 payment-info d-flex justify-content-end align-items-center">
                        <span class="ms-2 badge bg-info fs-5">
                            {{ receipt.payment.amount }}
                        </span>
                    </div>
                </div>
                <div class="col-12 table-responsive mb-4">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center text-nowrap">
                                    <i class="fas fa-ticket-alt me-2"></i>
                                    <span>{{ $t('myTravels.table.fare') }}</span>
                                </th>
                                <th class="text-center text-nowrap">
                                    <i class="far fa-clock me-2"></i>
                                    <span>{{ $t('myTravels.table.validFrom') }}</span>
                                </th>
                                <th class="text-center text-nowrap">
                                    <i class="far fa-clock me-2"></i>
                                    <span>{{ $t('myTravels.table.validTo') }}</span>
                                </th>
                                <th class="text-center text-nowrap">
                                    <i class="fas fa-money-bill-wave-alt fa-fw me-2"></i>
                                    <span>{{ $t('bankCards.myPayments.table.amount') }}</span>
                                </th>
                                <th class="text-center text-nowrap">
                                    <i class="fas fa-thumbtack me-2"></i>
                                    <span>{{ $t('myTravels.table.firstValidation') }}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(ticket, index) in receipt.tickets" :key="index" class="font-monospace">
                                <td class="text-center">
                                    {{ ticket.fare_name }}
                                </td>
                                <td class="text-center">
                                    {{ ticket.valid_from }}
                                </td>
                                <td class="text-center">
                                    {{ ticket.valid_to }}
                                </td>
                                <td class="text-center">
                                    {{ ticket.ticket_amount }}
                                </td>
                                <td class="text-center">
                                    <span v-for="(transit_data, index) in ticket.first_validation_transit_data" :key="index">
                                        <span class="badge bg-secondary">
                                            {{ transit_data.key }}
                                        </span>
                                        <span class="badge bg-success">
                                            {{ transit_data.val }}
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 mt-4">
                    <span v-if="this.$store.state.config.oms_api.key == '188762d1-41c4-4fcc-88db-b0508d2e67ba'">
                        {{ $t('receipt.conditions') }}
                        <a href="https://www.lineelecco.it" target="_blank">
                            www.lineelecco.it
                        </a>
                    </span>
                </div>
            </main>
            <footer class="d-flex justify-content-between align-items-center">
                <div class="col-12 text-center p-4">
                    <small v-if="this.$store.state.config.oms_api.key == '188762d1-41c4-4fcc-88db-b0508d2e67ba'">
                        Linee Lecco S.p.A. si riserva tutti i diritti concessi al presente documento e a quanto in esso contenuto, è vietata ogni sua riproduzione totale o parziale in assenza di specifica autorizzazione Linee Lecco S.p.A. Società per Azioni con Unico Socio Sede Legale Amministrativa e Deposito: Piazza Bione, 15 - 23900 Lecco P.I. 02320500131 Cod. Fisc. 92004770134 c/c 10390227 R.E.A. 232420 Capitale Sociale Interamente Versato € 1.056.242,50
                    </small>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import html2pdf from "html2pdf.js";
export default {
    name: 'Receipt',
    data() {
        return {
            receipt: this.$store.state.receipt,
            preview: true
        }
    },
    methods: {
        setupPdf() {
            return new Promise((resolve) => {
                html2pdf(document.getElementById("receipt_pdf"), {
                    margin: 1,
                    filename: `${this.$t('bankCards.myReceipt.receipt')}-${this.receipt.payment.short_id}.pdf`
                })
                resolve()
            })
            
        }, 
        downloadPdf() {
            $('#receipt_pdf').removeClass('col-xxl-8')
            this.setupPdf().then(
                () => {
                    $('#receipt_pdf').addClass('col-xxl-8')
                }
            )
        } 
    },
    created() {
        if (this.$store.state.receipt == null) {
            this.$router.replace('/payments')
        }
    },
    unmounted() {
        this.$store.state.receipt = null
    }
}
</script>

<style lang="scss" scoped>
    #receipt_pdf {
        position: relative;
        height: 1116px;
        overflow: hidden;
    }

    .receipt-title {
        border: 2px solid black;
    }

    footer {
        .col-12 {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    @media screen and (max-width: 576px) {

        .payment-info {
            justify-content: center !important;
        }
        .payment-info:last-child {
            margin-top: 10px;
        }

    }
</style>